import { AudienceTargetId, AudienceTargetItem, audienceTargetMap } from './consts'

import {
  AdGroupAudience, AudienceAccount,
  AudienceAccountType,
  AudienceEngagement,
  AudienceEngagementAction,
  AudienceExternalAccount,
  AudienceTimePeriod,
} from './types'

type ParsedEngagement = {
  action: AudienceEngagementAction
  quantity: number
  days?: number
  weeks?: number
  months?: number
}

export const parseEngagements = (jsonString: string): AudienceEngagement[] => {
  if (!jsonString) {
    return []
  }

  const parsedEngagements = JSON.parse(jsonString) as ParsedEngagement[]

  return parsedEngagements.map((item, index) => {
    let period = AudienceTimePeriod.Day
    let periodValue = item?.days ?? 0

    if (item?.weeks) {
      period = AudienceTimePeriod.Week
      periodValue = item.weeks
    }
    else if (item?.months) {
      period = AudienceTimePeriod.Month
      periodValue = item.months
    }

    return {
      id: index.toString(),
      action: item.action,
      quantity: item.quantity || 0,
      period,
      periodValue,
    }
  })
}

export const buildAudienceCpm = (audience: AdGroupAudience, isDataOnly = false): number => {
  // by default, we use displayCpm
  let cpm = audience.displayCpm || 0

  // For DOAG we use socialCpm
  if (isDataOnly && audience.socialCpm > 0) {
    cpm = audience.socialCpm
  }

  return cpm
}

export const getAudienceTarget = (id: AudienceTargetId): AudienceTargetItem => {
  return audienceTargetMap[id] ?? {
    id: 0,
    name: '',
    label: '',
    title: '',
    longTitle: '',
  }
}

export const parseExternalAccounts = (externalAccounts: AudienceExternalAccount[]) => {
  return externalAccounts.reduce<Record<string, AudienceAccount[]>>((accounts, account) => {
    const { accType, accId, channel } = account

    const channelAccounts = accounts[channel] ?? []
    channelAccounts.push({ id: accId, type: accType as AudienceAccountType })

    accounts[channel] = channelAccounts

    return accounts
  }, {})
}
