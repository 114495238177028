<template>
  <Box flex direction="col" spaceY="5">
    <AudienceSearch
      v-if="!disabled"
      :selectedAudiences="audiences"
      hideOnClick
      @select="handleSelect"
    />

    <DraggableNested
      v-if="audiences.length > 0"
      v-model="audienceGroups"
      group="audiences"
      :maxDepth="1"
      :draggableProps="{ handle: `[data-role=draggable]`, spaceY: '2', boxProps: { mb: '2' } }"
      :convertItem="convertItem"
      :itemComponent="AudienceSelectItem"
      :itemProps="{
        draggable: max > 1 && !disabled && audiences.length > 1,
        targetable,
        targetDisabled: disabled,
        disabled,
      }"
      :emptyInsertThreshold="10"
    />
    <Box v-else justify="center" alignItems="center" flex direction="col" spaceY="4" py="6">
      <Paper
        color="secondary-light"
        rounded="xl"
        flex
        justify="center"
        alignItems="center"
        height="48px"
        width="48px"
      >
        <Icon icon="search" variant="filled" color="secondary" size="lg" />
      </Paper>
      <Typography variant="body2" color="textPrimary">
        To get started, search for an audience.
      </Typography>
    </Box>
    <Alert v-if="shouldShowNotice" color="warning">
      Current HCP audience targeting selections will be open to all users. Switch to an AND operator to both negatively and positively target HCP audiences.
    </Alert>
  </Box>
</template>

<script setup lang="ts">
import { isEqual, omit, partition } from 'lodash-es'
import { Alert, Box, DraggableNested, DraggableNestedConvertItem, Icon, Paper, Typography } from '@lasso/luikit'
import { computed } from 'vue'

import { AdGroupAudience, TargetingGroup, TargetingItem } from './types'
import { AudienceSearch } from './AudienceSearch'
import AudienceSelectItem from './AudienceSelectItem.vue'

import { useCardAudiences } from './useCardAudiences'

const props = defineProps<{
  audiences: AdGroupAudience[]
  max: number
  disabled: boolean
  targetable: boolean
}>()

const emits = defineEmits<{
  'select': [AdGroupAudience]
  'update:audiences': [AdGroupAudience[]]
}>()

const getStartingGroupId = (audiences: Array<{ groupId: number | null }>) => {
  return audiences.reduce((highestGroupId, audience) => Math.max(highestGroupId, audience.groupId ?? -1), 0) + 1
}

const buildItem = (audience: AdGroupAudience, groupId: number): TargetingItem => {
  return {
    ...audience,
    groupId,
    type: 'item',
  }
}

const buildAudience = (item: TargetingItem, groupId: number): AdGroupAudience => {
  return {
    ...omit(item, 'type'),
    groupId,
  }
}

const audiencesInternal = computed({
  get: () => props.audiences,
  set: (value) => {
    if (!isEqual(value, props.audiences)) {
      emits('update:audiences', value)
    }
  },
})

const { isDirectSold } = useCardAudiences()!

const shouldShowNotice = computed(() => {
  return isDirectSold
    ? partition(audiencesInternal.value, audience => audience.excluded).every(part => part.length > 0)
    : false
})

const audienceGroups = computed({
  get: (): TargetingGroup | TargetingItem => {
    const items = audiencesInternal.value.map(audience => buildItem(audience, audience.groupId))
    const groups = items.reduce((groups, item) => {
      const group = groups.get(item.groupId) ?? []
      group.push(item)
      groups.set(item.groupId, group)

      return groups
    }, new Map<number, TargetingItem[]>())

    return {
      groupId: null,
      items: Array.from(groups.entries()).map(([groupId, group]): TargetingGroup | TargetingItem => {
        return group.length === 1
          ? group[0]!
          : {
              groupId,
              items: group,
              type: 'list',
              op: 'and',
            }
      }),
      type: 'list',
      op: 'or',
    }
  },
  set: (value) => {
    let groupIdCounter = getStartingGroupId(value.items ?? [])

    const audiences = (value.items ?? []).flatMap((group) => {
      const groupId = group.groupId ?? groupIdCounter++

      if (group.type === 'list') {
        return group.items.map(item => buildAudience(item as TargetingItem, groupId))
      }
      else {
        return buildAudience(group, groupId)
      }
    })

    audiencesInternal.value = audiences
  },
})

const handleSelect = (audience: AdGroupAudience) => {
  if (props.disabled) {
    return
  }

  audience = { ...audience, groupId: getStartingGroupId(audiencesInternal.value) }

  const isSelected = props.audiences.some(item => item.id === audience.id)
  const shouldDeselect = isSelected && props.max === 1
  const shouldSelect = props.audiences.length < props.max
  const firstSelectedAudience = audiencesInternal.value[0]
  const shouldReplace = props.max === 1 && firstSelectedAudience

  if (shouldDeselect) {
    audiencesInternal.value = props.audiences.filter(item => item.id !== audience.id)
  }
  else if (shouldSelect) {
    audiencesInternal.value = [...props.audiences, audience]
  }
  else if (shouldReplace) {
    audiencesInternal.value = [audience]
  }

  emits('select', audience)
}

const convertItem: DraggableNestedConvertItem<TargetingGroup | TargetingItem> = (item) => {
  return item.type === 'list'
    ? null
    : {
        groupId: null,
        type: 'list',
        op: 'and',
      }
}
</script>
